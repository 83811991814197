if (document.querySelector("#axeImages")) {
  gsap.set("#axeImages", {
    opacity: 0,
  });

  var axesImages = gsap.timeline({ repeat: -1 });

  axesImages
    .set(
      "#axeImages",
      {
        opacity: 1,
      },
      "+=3"
    )
    .to("#axeImages", {
      motionPath: {
        path: "#pathImages",
        align: "#pathImages",
        alignOrigin: [0.5, 0.5],
        autoRotate: false,
        start: 0.5,
        end: 1,
      },
      rotation: 3600,
      transformOrigin: "50% 50%",
      duration: 2.5,
      ease: CustomEase.create("custom", "M0,0 C0,0 0.161,0.251 0.425,0.425 0.76,0.646 1,1 1,1 "),
    })
    .set("#axeImages", {
      scaleX: -1,
    })
    .to(
      "#axeImages",
      {
        motionPath: {
          path: "#pathImages",
          align: "#pathImages",
          alignOrigin: [0.5, 0.5],
          autoRotate: false,
          start: 0.95,
          end: 0.5,
        },
        rotation: -360,
        transformOrigin: "50% 50%",
        duration: 3,
        ease: CustomEase.create("custom", "M0,0 C0,0 0.161,0.251 0.425,0.425 0.76,0.646 1,1 1,1 "),
      },
      "+=3"
    );
}

if (document.querySelector(".animation")) {
  document.querySelectorAll(".animation").forEach((animation) => {
    var d = 3,
      r1 = 3600,
      r2 = -720,
      r3 = -360,
      gap = 4;
    if (window.innerWidth < 768) {
      (d = 1.5), (r1 = 1800), (r2 = -360), (r3 = -180);
    }

    var path1 = animation.querySelector(".path1"),
      path2 = animation.querySelector(".path2"),
      path3 = animation.querySelector(".path3"),
      axe1 = animation.querySelector(".axe1"),
      axe2 = animation.querySelector(".axe2"),
      axe3 = animation.querySelector(".axe3");

    MotionPathPlugin.convertToPath(path1);
    MotionPathPlugin.convertToPath(path2);
    MotionPathPlugin.convertToPath(path3);

    var path1 = animation.querySelector(".path1"),
      path2 = animation.querySelector(".path2"),
      path3 = animation.querySelector(".path3");

    gsap.set(axe2, {
      opacity: 0,
    });
    gsap.set(axe3, {
      opacity: 0,
    });
    setTimeout(() => {
      gsap.set(axe2, {
        opacity: 1,
      });
    }, (d + gap) * 1000);
    setTimeout(() => {
      gsap.set(axe3, {
        opacity: 1,
      });
    }, (d + gap) * 2000);

    var axes = gsap.timeline({ repeat: -1 });

    axes
      .to(axe1, {
        motionPath: {
          path: path1,
          align: path1,
          alignOrigin: [0.5, 0],
          autoRotate: false,
          start: 0.5,
          end: 1,
        },
        rotation: r1,
        transformOrigin: "50% 50%",
        duration: d,
        ease: CustomEase.create("custom", "M0,0 C0,0 0.161,0.251 0.425,0.425 0.76,0.646 1,1 1,1 "),
      })
      .to(
        axe2,
        {
          motionPath: {
            path: path2,
            align: path2,
            alignOrigin: [0.5, 0.5],
            autoRotate: false,
            start: 0.5,
            end: 1,
          },
          rotation: r1,
          transformOrigin: "50% 50%",
          duration: d,
          ease: CustomEase.create("custom", "M0,0 C0,0 0.161,0.251 0.425,0.425 0.76,0.646 1,1 1,1 "),
        },
        "+=4"
      )
      .to(
        axe3,
        {
          motionPath: {
            path: path3,
            align: path3,
            alignOrigin: [0.5, 0.1],
            autoRotate: false,
            start: 0.5,
            end: 1,
          },
          rotation: r1,
          transformOrigin: "50% 50%",
          duration: d,
          ease: CustomEase.create("custom", "M0,0 C0,0 0.161,0.251 0.425,0.425 0.76,0.646 1,1 1,1 "),
        },
        "+=4"
      )
      .set(axe1, {
        scaleX: -1,
      })
      .set(axe2, {
        scaleX: -1,
      })
      .set(axe3, {
        scaleX: -1,
      })
      .to(
        axe3,
        {
          motionPath: {
            path: path3,
            align: path3,
            alignOrigin: [0.5, 0.1],
            autoRotate: false,
            start: 1,
            end: 0.5,
          },
          rotation: r3,
          transformOrigin: "50% 50%",
          duration: d,
          ease: CustomEase.create("custom", "M0,0 C0,0 0.161,0.251 0.425,0.425 0.76,0.646 1,1 1,1 "),
        },
        "+=4"
      )
      .to(
        axe2,
        {
          motionPath: {
            path: path2,
            align: path2,
            alignOrigin: [0.5, 0.5],
            autoRotate: false,
            start: 1,
            end: 0.5,
          },
          rotation: r2,
          transformOrigin: "50% 50%",
          duration: d,
          ease: CustomEase.create("custom", "M0,0 C0,0 0.161,0.251 0.425,0.425 0.76,0.646 1,1 1,1 "),
        },
        "+=4"
      )
      .to(
        axe1,
        {
          motionPath: {
            path: path1,
            align: path1,
            alignOrigin: [0.5, 0],
            autoRotate: false,
            start: 1,
            end: 0.5,
          },
          rotation: r3,
          transformOrigin: "50% 50%",
          duration: d,
          ease: CustomEase.create("custom", "M0,0 C0,0 0.161,0.251 0.425,0.425 0.76,0.646 1,1 1,1 "),
        },
        "+=4"
      );
  });
}

// lenis
const lenis = new Lenis({
  lerp: 0.06,
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

lenis.on("scroll", ScrollTrigger.update);

gsap.ticker.add((time) => {
  lenis.raf(time * 1000);
});

jQuery(document).ready(function ($) {
  if (navigator.userAgent.indexOf("Mac") > 0 || navigator.userAgent.indexOf("iPhone") > 0) {
    $("body").addClass("mac-os");
  }

  // notice bar
  !(function () {
    var topbar = $(".topbar-wrapper");
    if (topbar.length > 0) {
      var topbar_height = topbar.outerHeight(),
        topbar_pos = 0;

      if ($(".has-scroll-indicator").length) {
        topbar_height = topbar.outerHeight() + 4;
        topbar_pos = 4;
      }

      setTimeout(function () {
        gsap.to(topbar, {
          opacity: 1,
          y: topbar_pos,
          ease: "none",
        });
        gsap.to(".header", {
          paddingTop: topbar_height,
          ease: "none",
        });
        gsap.to(".global-wrapper", {
          paddingTop: topbar_height,
          ease: "none",
        });
        document.querySelector("body").style.setProperty("--header-top", -topbar_height + "px");
      }, 3000);

      $(window).on("resize", function () {
        var topbar_height = topbar.outerHeight();
        $(".global-wrapper").css("padding-top", topbar_height);
        document.querySelector("body").style.setProperty("--header-top", -topbar_height + "px");
      });
    }
  })();

  // in-view detect
  !(function () {
    const blocks = document.querySelectorAll(".in-view-detect, .fade-up, .fade-up-wrapper > *");

    [].forEach.call(blocks, ($item) => {
      function onScroll() {
        let delta = $item.getBoundingClientRect().top - window.innerHeight <= ($item.offsetHeight * -1) / 4 && !$item.classList.contains("in-view");

        if (delta) {
          setTimeout(() => {
            $item.classList.remove("in-view-detect");
            $item.classList.add("in-view");
          }, 200);
        }
      }
      onScroll();

      lenis.on("scroll", (e) => {
        onScroll();
      });
    });
  })();

  if ($(".block-testimonials").length) {
    const testimonialsSlider = new Swiper(".block-testimonials .swiper", {
      loop: true,
      spaceBetween: 15,
      initialSlide: 1,
      slidesPerView: 1.2,
      centeredSlides: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".block-testimonials .swiper-button-next",
        prevEl: ".block-testimonials .swiper-button-prev",
      },
      breakpoints: {
        576: {
          slidesPerView: 1.5,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: "auto",
          spaceBetween: 30,
        },
      },
      on: {
        init: function () {
          ScrollTrigger.refresh();
        },
      },
    });
  }

  if ($(".ticker-slider").length) {
    document.querySelectorAll(".ticker-slider").forEach((ticker) => {
      let speed = 5000,
        rev = false;

      if (ticker.classList.contains("ticker-slider--text")) {
        speed = 10000;
      }
      if (ticker.classList.contains("ticker-slider--reversed")) {
        rev = true;
      }
      document.querySelectorAll(".ticker-slider .swiper-sline:nth-child(2n)").forEach((slide) => {
        slide.classList.add(even);
      });

      const tickerSlider = new Swiper(ticker, {
        loop: true,
        spaceBetween: 5,
        slidesPerView: "auto",
        allowTouchMove: false,
        speed: speed,
        autoplay: {
          delay: 0,
          reverseDirection: rev,
          disableOnInteraction: false,
        },
        breakpoints: {
          768: {
            spaceBetween: 8,
          },
          1024: {
            spaceBetween: 10,
          },
        },
        on: {
          init: function () {
            ScrollTrigger.refresh();
          },
        },
      });
    });
  }

  let mm = gsap.matchMedia();

  mm.add("(max-width: 1023px)", () => {
    if ($(".block-cards").length) {
      const cardsSlider = new Swiper(".block-cards .swiper", {
        loop: true,
        spaceBetween: 15,
        initialSlide: 1,
        slidesPerView: 1.2,
        centeredSlides: true,
        breakpoints: {
          576: {
            slidesPerView: 1.5,
            spaceBetween: 15,
          },
        },
        on: {
          init: function () {
            ScrollTrigger.refresh();
          },
        },
      });
    }
  });
  mm.add("(min-width: 1024px)", () => {
    if ($(".block-cards").length) {
      let cards = document.querySelectorAll(".block-cards .swiper-slide"),
        r = 5;
      cards.forEach((card) => {
        gsap.to(card, {
          scrollTrigger: {
            trigger: card,
            start: "top bottom",
            end: "bottom top",
            scrub: true,
          },
          keyframes: {
            rotation: [0, r, r],
            opacity: [0.2, 1, 0.2],
            zIndex: [0, 9, 0],
          },
          ease: "none",
        });

        r = -r;
      });
    }
  });

  //header
  var header = document.querySelector(".header");
  var headroom = new Headroom(header, {
    offset: 40,
  });
  headroom.init();

  $(".header .navbar-toggler").on("click", function () {
    if ($(this).hasClass("active")) {
      const scrollY = document.body.style.top;
      $("body").css({ position: "initial", top: 0 });
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      $(this).removeClass("active");
      setTimeout(() => {
        $(".header").removeClass("nav-opened");
      }, 300);
      $(".menu-mobile").removeClass("active");
      headroom.unfreeze();
    } else {
      const scrollY = $(window).scrollTop();
      $("body").css({ position: "fixed", top: -scrollY });
      $(this).addClass("active");
      $(".header").addClass("nav-opened");
      $(".menu-mobile").addClass("active");
      $(".search-wrapper").removeClass("active");
      $(".btn-search").removeClass("active");
      headroom.freeze();
    }
  });

  // fixed btn {
  var btnFixed = document.querySelector(".btn-fixed");
  if (btnFixed) {
    var headroom1 = new Headroom(btnFixed, {
      offset: window.innerHeight,
    });
    headroom1.init();
  }

  // headlines
  var splits = document.querySelectorAll("h1, h2, .card-headline h3, .block-headline h3, .block-content-text h3");

  splits.forEach((txt) => {
    const text = SplitType.create(txt);

    var lines = txt.querySelectorAll(".line"),
      linesTotal = txt.querySelectorAll(".line").length;
    lines.forEach((line, i) => {
      if (line.nextElementSibling) {
        var currentW = line.clientWidth,
          nextW = line.nextElementSibling.clientWidth;
        if (currentW < nextW) {
          line.classList.add("line-short-bottom");
        } else {
          line.classList.add("line-long-bottom");
        }
      }

      if (line.previousElementSibling) {
        var currentW = line.clientWidth,
          prevW = line.previousElementSibling.clientWidth;
        if (currentW < prevW) {
          line.classList.add("line-short-top");
        } else {
          line.classList.add("line-long-top");
        }
      }
    });

    var chars = txt.querySelectorAll(".char");

    gsap.to(txt, {
      scrollTrigger: {
        trigger: txt,
        start: "top 95%",
      },
      y: 0,
      opacity: 1,
      duration: 0.5,
      delay: 0.1,
      ease: "none",
    });

    gsap.to(chars, {
      scrollTrigger: {
        trigger: txt,
        start: "top 95%",
      },
      y: 0,
      opacity: 1,
      duration: 0.3,
      delay: 0.2,
      ease: "power1.out",
    });

    splits.forEach((el) => {
      el.style.visibility = "visible";
    });
  });

  document.querySelectorAll(".subtitle").forEach((subtitle) => {
    gsap.to(subtitle, {
      scrollTrigger: {
        trigger: subtitle,
        start: "top 70%",
      },
      opacity: 1,
      duration: 0.5,
      delay: 0.5,
      ease: "none",
    });
  });

  $("body").imagesLoaded(function () {
    ScrollTrigger.refresh();
  });

  function score() {
    const modalScore = new bootstrap.Modal(document.getElementById("modalScore"));
    let totalItems = [];
    $(".sticker").each(function (i) {
      if ($(this).css("display") !== "none") {
        totalItems.push($(this));
      }
    });
    const total = totalItems.length;
    var counter = 0;
    $("#scoreTotal").text(total);
    $("#scoreCounter").text(counter);

    $(".sticker").on("click", function () {
      $(this).addClass("active");
      counter++;
      $("#scoreCounter").text(counter);

      if (counter === total) {
        $(".score").fadeOut(200);
        modalScore.show();
      }
    });
  }
  if ($(".sticker").length) {
    var btnScore = document.querySelector(".score");
    var headroom2 = new Headroom(btnScore);
    headroom2.init();

    score();
  }

  $(".btn-book").on("click", function (e) {
    e.preventDefault();
  });
});

// YouTube video
const modalVideoEl = document.getElementById("modalVideo");
if (modalVideoEl) {
  const modalVideo = new bootstrap.Modal("#modalVideo");

  const modalFrame = document.getElementById("videoFrame");

  const modalToggle = document.querySelectorAll(".btn-video");

  modalToggle.forEach((el) => {
    el.addEventListener("click", function () {
      var source = this.dataset.src;
      modalFrame.setAttribute("src", source);
      modalVideo.show();
      modalFrame.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
    });
  });

  modalVideoEl.addEventListener("hidden.bs.modal", function (event) {
    console.log(modalFrame.contentWindow);
    modalFrame.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', "*");
    modalFrame.setAttribute("src", "");
  });
}

function shuffleElementTexts(item, shuffleFn) {
  if ((!item.children.length || item.dataset.allowShuffle != undefined) && item.innerText) {
    // Remove hidden content
    // item.querySelectorAll(".u-screen-reader-text").forEach((item) => item.remove());
    // Wrap line breaks with spaces
    const words = item.innerText.replace("\n", " \n ").split(" ");
    // Actually shuffle each "word"
    for (let i = 0; i < words.length; i++) {
      const chars = [...words[i]].map((item) => item[0]);
      words[i] = shuffleFn(chars);
    }
    // Output result
    item.innerText = words.join(" ");
  }
}

function shuffle(input) {
  var a = typeof input == "string" ? input.split("") : input,
    n = a.length;

  for (var i = n - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var tmp = a[i];
    a[i] = a[j];
    a[j] = tmp;
  }
  return a.join("");
}

const shuffleElements = document.querySelectorAll("p a, ul a, .navigation-main a, .footer-menu a, .copyright  a");
if (shuffleElements.length > 0) {
  shuffleElements.forEach((el) => {
    el.setAttribute("aria-label", el.innerText);

    el.addEventListener("mouseenter", function (e) {
      if (window.innerWidth >= 1024) {
        const $el = e.currentTarget;

        let tw = gsap.timeline({
          onComplete: () => {
            $el.innerText = $el.getAttribute("aria-label");
          },
        });

        const shufflePerSecond = 4;
        const DURATION = 0.25;
        const shuffleDelay = DURATION / shufflePerSecond;

        for (let i = 0; i < shufflePerSecond; i++) {
          tw.add(() => {
            shuffleElementTexts($el, shuffle);
          }, shuffleDelay * i);
        }
      }
    });

    el.addEventListener("mouseleave", function (e) {
      el.innerText = el.getAttribute("aria-label");
    });
  });
}

if (document.querySelector("canvas")) {
  document.querySelectorAll("canvas").forEach((canvas) => {
    function pixelate(v) {
      var v = v;
      // if in play mode use that value, else use slider value
      var size = v * 0.01,
        // cache scaled width and height
        w = canvas.width * size,
        h = canvas.height * size;

      // draw original image to the scaled size
      ctx.drawImage(img, 0, 0, w, h);

      // then draw that scaled image thumb back to fill canvas
      // As smoothing is off the result will be pixelated
      ctx.drawImage(canvas, 0, 0, w, h, 0, 0, canvas.width, canvas.height);
    }

    var ctx = canvas.getContext("2d"),
      img = new Image(),
      play = false;

    ctx.mozImageSmoothingEnabled = false;
    ctx.webkitImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;

    img.onload = function () {
      pixelate(5);
    };

    img.src = canvas.dataset.src;

    ScrollTrigger.create({
      trigger: canvas,
      start: "top center",
      onEnter: function () {
        var v = 5;

        for (var i = 5; i < 100; i++) {
          pixelate(v);
          v += 5;
        }
      },
    });
  });
}

// stickers
let r = -10,
  t = -60;
gsap.utils.toArray(".banner-home .sticker, .banner-page .sticker, .block-promo .sticker, .block-contact .sticker").forEach(function (elem) {
  gsap.to(elem, {
    scrollTrigger: {
      trigger: elem,
      start: "top bottom",
      end: "top top",
      scrub: true,
    },
    y: t,
    rotation: r,
  });
  r = -r;
  t = -t;
});

var Fo = (function () {
  function e(t) {
    void 0 === t && (t = {}),
      (this.options = Object.assign({}, { el: null, container: document.body, className: "mf-cursor", innerClassName: "mf-cursor-inner", textClassName: "mf-cursor-text", mediaClassName: "mf-cursor-media", mediaBoxClassName: "mf-cursor-media-box", iconSvgClassName: "mf-svgsprite", iconSvgNamePrefix: "-", iconSvgSrc: "", dataAttr: "cursor", hiddenState: "-hidden", textState: "-text", iconState: "-icon", activeState: "-active", mediaState: "-media", stateDetection: { "-pointer": "a,button" }, visible: !0, visibleOnState: !1, speed: 0.55, ease: "expo.out", overwrite: !0, skewing: 0, skewingText: 2, skewingIcon: 2, skewingMedia: 2, skewingDelta: 0.001, skewingDeltaMax: 0.15, stickDelta: 0.15, showTimeout: 0, hideOnLeave: !0, hideTimeout: 300, hideMediaTimeout: 300, initialPos: [-window.innerWidth, -window.innerHeight] }, t)),
      this.options.visible && null == t.stateDetection && (this.options.stateDetection["-hidden"] = "iframe"),
      (this.gsap = e.gsap || window.gsap),
      (this.el = "string" == typeof this.options.el ? document.querySelector(this.options.el) : this.options.el),
      (this.container = "string" == typeof this.options.container ? document.querySelector(this.options.container) : this.options.container),
      (this.skewing = this.options.skewing),
      (this.pos = { x: this.options.initialPos[0], y: this.options.initialPos[1] }),
      (this.vel = { x: 0, y: 0 }),
      (this.event = {}),
      (this.events = []),
      this.init();
  }

  var t = e.prototype;
  return (
    (t.init = function () {
      this.el || this.create(), this.createSetter(), this.bind(), this.render(!0), (this.ticker = this.render.bind(this, !1)), this.gsap.ticker.add(this.ticker);
    }),
    (t.create = function () {
      (this.el = document.createElement("div")), (this.el.className = this.options.className), this.el.classList.add(this.options.hiddenState), (this.inner = document.createElement("div")), (this.inner.className = this.options.innerClassName), (this.text = document.createElement("div")), (this.text.className = this.options.textClassName), (this.media = document.createElement("div")), (this.media.className = this.options.mediaClassName), (this.mediaBox = document.createElement("div")), (this.mediaBox.className = this.options.mediaBoxClassName), this.media.appendChild(this.mediaBox), this.inner.appendChild(this.media), this.inner.appendChild(this.text), this.el.appendChild(this.inner), this.container.appendChild(this.el);
    }),
    (t.createSetter = function () {
      this.setter = { x: this.gsap.quickSetter(this.el, "x", "px"), y: this.gsap.quickSetter(this.el, "y", "px"), rotation: this.gsap.quickSetter(this.el, "rotation", "deg"), scaleX: this.gsap.quickSetter(this.el, "scaleX"), scaleY: this.gsap.quickSetter(this.el, "scaleY"), wc: this.gsap.quickSetter(this.el, "willChange"), inner: { rotation: this.gsap.quickSetter(this.inner, "rotation", "deg") } };
    }),
    (t.bind = function () {
      var e = this;
      (this.event.mouseleave = function () {
        return e.hide();
      }),
        (this.event.mouseenter = function () {
          return e.show();
        }),
        (this.event.mousedown = function () {
          return e.addState(e.options.activeState);
        }),
        (this.event.mouseup = function () {
          return e.removeState(e.options.activeState);
        }),
        (this.event.mousemoveOnce = function () {
          return e.show();
        }),
        (this.event.mousemove = function (t) {
          e.gsap.to(e.pos, {
            x: e.stick ? e.stick.x - (e.stick.x - t.clientX) * e.options.stickDelta : t.clientX,
            y: e.stick ? e.stick.y - (e.stick.y - t.clientY) * e.options.stickDelta : t.clientY,
            overwrite: e.options.overwrite,
            ease: e.options.ease,
            duration: e.visible ? e.options.speed : 0,
            onUpdate: function () {
              return (e.vel = { x: t.clientX - e.pos.x, y: t.clientY - e.pos.y });
            },
          });
        }),
        (this.event.mouseover = function (t) {
          for (var i = t.target; i && i !== e.container && (!t.relatedTarget || !i.contains(t.relatedTarget)); i = i.parentNode) {
            for (var n in e.options.stateDetection) i.matches(e.options.stateDetection[n]) && e.addState(n);
            if (e.options.dataAttr) {
              var r = e.getFromDataset(i);
              r.state && e.addState(r.state), r.text && e.setText(r.text), r.icon && e.setIcon(r.icon), r.img && e.setImg(r.img), r.video && e.setVideo(r.video), void 0 !== r.show && e.show(), void 0 !== r.stick && e.setStick(r.stick || i);
            }
          }
        }),
        (this.event.mouseout = function (t) {
          for (var i = t.target; i && i !== e.container && (!t.relatedTarget || !i.contains(t.relatedTarget)); i = i.parentNode) {
            for (var n in e.options.stateDetection) i.matches(e.options.stateDetection[n]) && e.removeState(n);
            if (e.options.dataAttr) {
              var r = e.getFromDataset(i);
              r.state && e.removeState(r.state), r.text && e.removeText(), r.icon && e.removeIcon(), r.img && e.removeImg(), r.video && e.removeVideo(), void 0 !== r.show && e.hide(), void 0 !== r.stick && e.removeStick();
            }
          }
        }),
        this.options.hideOnLeave && this.container.addEventListener("mouseleave", this.event.mouseleave, { passive: !0 }),
        this.options.visible && this.container.addEventListener("mouseenter", this.event.mouseenter, { passive: !0 }),
        this.options.activeState && (this.container.addEventListener("mousedown", this.event.mousedown, { passive: !0 }), this.container.addEventListener("mouseup", this.event.mouseup, { passive: !0 })),
        this.container.addEventListener("mousemove", this.event.mousemove, { passive: !0 }),
        this.options.visible && this.container.addEventListener("mousemove", this.event.mousemoveOnce, { passive: !0, once: !0 }),
        (this.options.stateDetection || this.options.dataAttr) && (this.container.addEventListener("mouseover", this.event.mouseover, { passive: !0 }), this.container.addEventListener("mouseout", this.event.mouseout, { passive: !0 }));
    }),
    (t.render = function (e) {
      if (!0 === e || (0 !== this.vel.y && 0 !== this.vel.x)) {
        if ((this.trigger("render"), this.setter.wc("transform"), this.setter.x(this.pos.x), this.setter.y(this.pos.y), this.skewing)) {
          var t = Math.sqrt(Math.pow(this.vel.x, 2) + Math.pow(this.vel.y, 2)),
            i = Math.min(t * this.options.skewingDelta, this.options.skewingDeltaMax) * this.skewing,
            n = (180 * Math.atan2(this.vel.y, this.vel.x)) / Math.PI;
          this.setter.rotation(n), this.setter.scaleX(1 + i), this.setter.scaleY(1 - i), this.setter.inner.rotation(-n);
        }
      } else this.setter.wc("auto");
    }),
    (t.show = function () {
      var e = this;
      this.trigger("show"),
        clearInterval(this.visibleInt),
        (this.visibleInt = setTimeout(function () {
          e.el.classList.remove(e.options.hiddenState), (e.visible = !0), e.render(!0);
        }, this.options.showTimeout));
    }),
    (t.hide = function () {
      var e = this;
      this.trigger("hide"),
        clearInterval(this.visibleInt),
        this.el.classList.add(this.options.hiddenState),
        (this.visibleInt = setTimeout(function () {
          return (e.visible = !1);
        }, this.options.hideTimeout));
    }),
    (t.toggle = function (e) {
      !0 === e || (!1 !== e && !this.visible) ? this.show() : this.hide();
    }),
    (t.addState = function (e) {
      var t;
      if ((this.trigger("addState", e), e === this.options.hiddenState)) return this.hide();
      (t = this.el.classList).add.apply(t, e.split(" ")), this.options.visibleOnState && this.show();
    }),
    (t.removeState = function (e) {
      var t;
      if ((this.trigger("removeState", e), e === this.options.hiddenState)) return this.show();
      (t = this.el.classList).remove.apply(t, e.split(" ")), this.options.visibleOnState && this.el.className === this.options.className && this.hide();
    }),
    (t.toggleState = function (e, t) {
      !0 === t || (!1 !== t && !this.el.classList.contains(e)) ? this.addState(e) : this.removeState(e);
    }),
    (t.setSkewing = function (e) {
      this.gsap.to(this, { skewing: e });
    }),
    (t.removeSkewing = function () {
      this.gsap.to(this, { skewing: this.options.skewing });
    }),
    (t.setStick = function (e) {
      var t = ("string" == typeof e ? document.querySelector(e) : e).getBoundingClientRect();
      this.stick = { y: t.top + t.height / 2, x: t.left + t.width / 2 };
    }),
    (t.removeStick = function () {
      this.stick = !1;
    }),
    (t.setText = function (e) {
      (this.text.innerHTML = e), this.addState(this.options.textState), this.setSkewing(this.options.skewingText);
    }),
    (t.removeText = function () {
      this.removeState(this.options.textState), this.removeSkewing();
    }),
    (t.setIcon = function (e, t) {
      void 0 === t && (t = ""), (this.text.innerHTML = "<svg class='" + this.options.iconSvgClassName + " " + this.options.iconSvgNamePrefix + e + "' style='" + t + "'><use xlink:href='" + this.options.iconSvgSrc + "#" + e + "'></use></svg>"), this.addState(this.options.iconState), this.setSkewing(this.options.skewingIcon);
    }),
    (t.removeIcon = function () {
      this.removeState(this.options.iconState), this.removeSkewing();
    }),
    (t.setMedia = function (e) {
      var t = this;
      clearTimeout(this.mediaInt),
        e && ((this.mediaBox.innerHTML = ""), this.mediaBox.appendChild(e)),
        (this.mediaInt = setTimeout(function () {
          return t.addState(t.options.mediaState);
        }, 20)),
        this.setSkewing(this.options.skewingMedia);
    }),
    (t.removeMedia = function () {
      var e = this;
      clearTimeout(this.mediaInt),
        this.removeState(this.options.mediaState),
        (this.mediaInt = setTimeout(function () {
          return (e.mediaBox.innerHTML = "");
        }, this.options.hideMediaTimeout)),
        this.removeSkewing();
    }),
    (t.setImg = function (e) {
      this.mediaImg || (this.mediaImg = new Image()), this.mediaImg.src !== e && (this.mediaImg.src = e), this.setMedia(this.mediaImg);
    }),
    (t.removeImg = function () {
      this.removeMedia();
    }),
    (t.setVideo = function (e) {
      this.mediaVideo || ((this.mediaVideo = document.createElement("video")), (this.mediaVideo.muted = !0), (this.mediaVideo.loop = !0), (this.mediaVideo.autoplay = !0)), this.mediaVideo.src !== e && ((this.mediaVideo.src = e), this.mediaVideo.load()), this.mediaVideo.play(), this.setMedia(this.mediaVideo);
    }),
    (t.removeVideo = function () {
      this.mediaVideo && this.mediaVideo.readyState > 2 && this.mediaVideo.pause(), this.removeMedia();
    }),
    (t.on = function (e, t) {
      this.events[e] instanceof Array || this.off(e), this.events[e].push(t);
    }),
    (t.off = function (e, t) {
      this.events[e] = t
        ? this.events[e].filter(function (e) {
            return e !== t;
          })
        : [];
    }),
    (t.trigger = function (e) {
      var t = arguments,
        i = this;
      this.events[e] &&
        this.events[e].forEach(function (e) {
          return e.call.apply(e, [i, i].concat([].slice.call(t, 1)));
        });
    }),
    (t.getFromDataset = function (e) {
      var t = e.dataset;
      return { state: t[this.options.dataAttr], show: t[this.options.dataAttr + "Show"], text: t[this.options.dataAttr + "Text"], icon: t[this.options.dataAttr + "Icon"], img: t[this.options.dataAttr + "Img"], video: t[this.options.dataAttr + "Video"], stick: t[this.options.dataAttr + "Stick"] };
    }),
    (t.destroy = function () {
      this.trigger("destroy"), this.gsap.ticker.remove(this.ticker), this.container.removeEventListener("mouseleave", this.event.mouseleave), this.container.removeEventListener("mouseenter", this.event.mouseenter), this.container.removeEventListener("mousedown", this.event.mousedown), this.container.removeEventListener("mouseup", this.event.mouseup), this.container.removeEventListener("mousemove", this.event.mousemove), this.container.removeEventListener("mousemove", this.event.mousemoveOnce), this.container.removeEventListener("mouseover", this.event.mouseover), this.container.removeEventListener("mouseout", this.event.mouseout), this.el && (this.container.removeChild(this.el), (this.el = null), (this.mediaImg = null), (this.mediaVideo = null));
    }),
    e
  );
})();
// Fo.registerGSAP(jn);
function initFollower() {
  window.matchMedia("(pointer:fine)").matches && (this.follower = new Fo({ className: "cb-cursor", innerClassName: "cb-cursor-inner", textClassName: "cb-cursor-text", mediaClassName: "cb-cursor-media", mediaBoxClassName: "cb-cursor-media-box", iconSvgClassName: "cb-svgsprite", iconSvgSrc: "/assets/sprites/svgsprites.svg?2", skewing: 1.5, skewingMedia: 0.5 }));
}
initFollower();
